import { Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";

import HomePage from "./Home";
import AboutPage from "./About";
import PodcastLoader from "./assets/img/enjaname-loader.svg";

function Icon(props) {
  return (
    <svg
      width={502}
      height={572}
      viewBox="0 0 502 572"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_b_382_5)">
        <path
          d="M78.4743 387.923C75.9571 406.51 77.745 423.278 78.3958 428.308C78.5448 429.445 78.6389 430.589 78.7173 431.733C81.1247 466.32 109.974 493.643 145.206 493.643C163.611 493.643 180.274 486.183 192.335 474.132C204.395 462.08 211.86 445.43 211.86 427.039V387.86H290.276V426.185C290.276 462.809 319.51 493.227 356.169 493.635C374.88 493.846 391.833 486.348 404.058 474.132C416.119 462.08 423.584 445.43 423.584 427.039V387.86H502V427.039C502 440.618 500.134 453.759 496.636 466.218C479.534 527.242 423.458 572 356.93 572C315.173 572 277.534 554.37 251.068 526.145C224.603 554.37 186.963 572 145.206 572C78.6781 572 22.6027 527.242 5.50017 466.218C3.68091 459.738 2.30079 453.077 1.40684 446.268C0.559949 439.976 0.136502 433.558 0.136502 427.039L0.00319459 404.582C-0.11443 384.436 3.01438 364.369 9.67975 345.352C23.975 304.59 48.8957 281.201 71.8481 266.658C101.842 247.656 130.856 247.656 130.856 247.656L358.263 246.872C401.557 243.268 416.864 208.297 421.639 192.187C422.933 187.814 423.576 183.285 423.576 178.725V144.953C423.576 126.563 416.111 109.912 404.05 97.8604C391.99 85.809 375.327 78.3494 356.922 78.3494C338.518 78.3494 321.855 85.809 309.794 97.8604C297.734 109.912 290.268 126.563 290.268 144.953V184.132H211.852V144.953C211.852 126.563 204.387 109.912 192.327 97.8604C180.266 85.809 163.603 78.3494 145.199 78.3494C126.794 78.3494 110.131 85.809 98.0705 97.8604C86.0101 109.912 78.5448 126.563 78.5448 144.953V184.132H0.136502V144.953C0.136502 131.374 2.00281 118.233 5.50017 105.774C22.6027 44.7577 78.6781 0 145.206 0C186.963 0 224.603 17.6304 251.068 45.8547C277.534 17.6304 315.173 0 356.93 0C423.458 0 479.534 44.7577 496.636 105.782C500.134 118.241 502 131.382 502 144.961V176.186C502 182.525 501.561 188.864 500.62 195.133C480.632 328.113 357.408 325.237 357.408 325.237H142.627C96.8942 325.237 82.4264 358.774 78.4821 387.868C78.4821 387.884 78.4821 387.908 78.4743 387.923Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_382_5"
          x={-20}
          y={-20}
          width={542}
          height={612}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation={10} />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_382_5"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_382_5"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

function App() {
  const [pageloader, setPageloader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageloader(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {pageloader ? (
        <div className="enjaname-loader w-100 grid place-center">
          <div className="text-center">
            <img className="mw-200" src={PodcastLoader} alt="" />
            <h1 className="loader-text f-24 f-w-400 text-tertiary text-uppercase mt-16 text2-reveal">
              Enjaname Podcast
            </h1>
          </div>
        </div>
      ) : (
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/about" element={<AboutPage />} />
        </Routes>
      )}
    </>
  );
}

export default App;
