import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Spotify } from "./spotify";

// images
import LogoImage from "./assets/img/logo.svg";
import PodcastLogo from "./assets/img/logo-bg1.png";
import SportifyLogo from "./assets/img/spotify.svg";
import GoogleLogo from "./assets/img/google.svg";
import AmazonLogo from "./assets/img/amazon.svg";
import AppleLogo from "./assets/img/apple.svg";
import PocketLogo from "./assets/img/pocketcast.svg";

function HomePage() {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 200);
    });
  }, []);
  return (
    <div className="enjaname-home-page">
      <div
        className={
          "w-100 header-section flex align-center " +
          (scroll ? "header-active" : "")
        }
      >
        <div className="container w-100">
          <div className="flex justify-between px-32 xs:px-20">
            <Link to="/">
              <img src={LogoImage} alt="Enjaname" />
            </Link>
            <div className="flex align-center">
              <Link
                to="/about"
                className="f-14 xs:f-14 f-w-500 text-primary text-hover-primary bg-white-opacity-1 px-16 py-8 xs:px-12 xs:py-6 radius-50 mr-8 xs:mr-2"
              >
                About
              </Link>
              <a
                href="https://linktr.ee/enjaname"
                target="_blank"
                rel="noreferrer"
                className="flex f-14 xs:f-14 f-w-500 text-primary bg-white-opacity-05 px-16 py-8 xs:px-12 xs:py-6 radius-50"
              >
                Podcast Links
              </a>
              {/* <Link to="/about">
                <h3 className="f-14 f-w-600 text-primary">
                  Contact and Support
                </h3>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
      <div className="enjaname-cover-section"></div>
      <div className="enjaname-topic-section flex justify-center align-center">
        <div className="flex xs:c-g-8 column-gap-140">
          <p className="topic-title f-12 xs:f-6 text-uppercase">Career</p>
          <p className="topic-title f-12 xs:f-6 text-uppercase">Questions</p>
          <p className="topic-title f-12 xs:f-6 text-uppercase">Failures</p>
          <p className="topic-title f-12 xs:f-6 text-uppercase">History</p>
          <p className="topic-title f-12 xs:f-6 text-uppercase">Politics</p>
          <p className="topic-title f-12 xs:f-6 text-uppercase">Story</p>
        </div>
      </div>
      <div className="enjaname-podcast-details">
        <div className="flex mt-60 xs:mt-40 xs:px-16">
          <div>
            <img src={PodcastLogo} className="logo-img" alt="" />
          </div>
          <div className="ml-12">
            <h1 className="f-32 xs:f-20 text-secondary">Enjaname Podcast</h1>
            <div className="flex xs:flex-column column-gap-8 mt-12">
              <a
                href="https://linktr.ee/enjaname"
                target="_blank"
                rel="noreferrer"
                className="flex xs:justify-center f-14 l-h-32 px-20 xs:px-12 py-4 text-secondary bg-white-opacity-05 radius-50"
              >
                Podcast Links
              </a>
              <a
                href="https://www.instagram.com/enjaname/"
                target="_blank"
                rel="noreferrer"
                className="flex xs:justify-center f-14 l-h-32 px-20 xs:px-12 py-4 xs:my-8 text-secondary bg-white-opacity-05 radius-50"
              >
                Instagram
              </a>
              <div className="flex align-center px-4 py-2 xs:py-6 bg-white-opacity-05 radius-50">
                <a
                  href="https://open.spotify.com/show/3tHiB0EyOteMykUCHhxJeL?utm_medium=share&utm_source=linktree"
                  target="_blank"
                  rel="noreferrer"
                  className="flex px-8 py-4"
                >
                  <img src={SportifyLogo} alt="" className="img-20" />
                </a>
                <a
                  href="https://podcasts.apple.com/us/podcast/enjaname-%E0%AE%8E%E0%AE%A9-%E0%AE%9C%E0%AE%A9%E0%AE%AE/id1693076585?ign-itscg=30200&ign-itsct=lt_p"
                  target="_blank"
                  rel="noreferrer"
                  className="flex px-8 py-4"
                >
                  <img src={AppleLogo} alt="" className="img-20" />
                </a>
                <a
                  href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy9lMmQ4Mjg0NC9wb2RjYXN0L3Jzcw"
                  target="_blank"
                  rel="noreferrer"
                  className="flex px-8 py-4"
                >
                  <img src={GoogleLogo} alt="" className="img-20" />
                </a>
                <a
                  href="https://music.amazon.in/podcasts/57fb7276-1c46-4a7a-8d68-af9ea2d87dce/enjaname-%E0%AE%8E%E0%AE%A9%E0%AF%8D%E0%AE%9C%E0%AE%A9%E0%AE%AE%E0%AF%87"
                  target="_blank"
                  rel="noreferrer"
                  className="flex px-8 py-4"
                >
                  <img src={AmazonLogo} alt="" className="img-20" />
                </a>
                <a
                  href="https://pca.st/6cz96wa4"
                  target="_blank"
                  rel="noreferrer"
                  className="flex px-8 py-4"
                >
                  <img src={PocketLogo} alt="" className="img-20" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="enjaname-podcast-episodes mt-24 xs:px-20">
        <h3 className="f-14 f-w-600 text-tertiary text-uppercase mb-16">
          Podcast Episodes
        </h3>
        <div className="flex flex-column row-gap-16">
          <Spotify
            wide
            link="https://open.spotify.com/episode/626rt5q4RE2xjWhQjHNFEK?si=904d04df07ea4a11"
          />
          <Spotify
            wide
            link="https://open.spotify.com/episode/2fxJkB0VMr0NiRFjfmZqk0?si=12a21608f0204c86"
          />
          <Spotify
            wide
            link="https://open.spotify.com/episode/0gW5BDkgO9lAd1fkM9cSj3?si=c12de5389c7f44e2"
          />
          <Spotify
            wide
            link="https://open.spotify.com/episode/3r8BsO0pzRUseS0ejx1lHe?si=18086fa3efd64cfb"
          />
          <Spotify
            wide
            link="https://open.spotify.com/episode/1mX95EFs2w2mWVrNXHmgsw?si=6a579529bb66492a"
          />
          <Spotify
            wide
            link="https://open.spotify.com/episode/1IeVDlvkZL9N3YqdyOaCxa?si=b35585fcb6474a4b"
          />
          <Spotify
            wide
            link="https://open.spotify.com/episode/40hnI4oxuFNiB4tLliGDpO?si=0f0547817e8649a5"
          />
          <Spotify
            wide
            link="https://open.spotify.com/episode/4lABnjqCzEsU0fO3jVAh9E?si=a153d2fa40ac475c"
          />
          <Spotify
            wide
            link="https://open.spotify.com/episode/2IyWc45Eakq2cnsrq1sKfu?si=de909bbbf1c848ff"
          />
          <Spotify
            wide
            link="https://open.spotify.com/episode/3r8BsO0pzRUseS0ejx1lHe?si=18086fa3efd64cfb"
          />
          <Spotify
            wide
            link="https://open.spotify.com/episode/1hpZA5GbftkWBvTRmIc8wc?si=3b987599f5014a0e"
          />
          <Spotify
            wide
            link="https://open.spotify.com/episode/7i1Lu0Sn7jPXi7uWRDWRs1?si=53cea1cc45684386"
          />
          <Spotify
            wide
            link="https://open.spotify.com/episode/3A7sJRbrSMU0TKYRnpg8sy?si=3d77e857e466453a"
          />
          <Spotify
            wide
            link="https://open.spotify.com/episode/1B9k394j7GL6fgutDorm3R?si=d6463326b65e4efb"
          />
        </div>
      </div>
      <div className="flex justify-center mt-24 border-top-primary mb-60">
        <a
          href="https://open.spotify.com/show/3tHiB0EyOteMykUCHhxJeL?si=fdb04fbbbd55469b"
          target="_blank"
          rel="noreferrer"
          className="f-14 bg-white-opacity-05 text-secondary px-24 py-12 radius-50"
        >
          View All episodes
        </a>
      </div>
      <div className="footer-section flex justify-between py-24 px-32 bg-white-opacity-05">
        <div className="container w-100 flex justify-between">
          <p className="f-13 f-w-400 text-primary">
            Anandakumar{" "}
            <a
              href="https://jeoanand.com"
              target="_blank"
              rel="noreferrer"
              className="text-secondary ml-6 radius-50"
            >
              @jeoanand
            </a>
          </p>
          <p className="f-13 f-w-400 text-tertiary">Made ❤️ TN, India</p>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
