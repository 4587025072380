import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// images
import LogoImage from "./assets/img/logo.svg";
import SportifyLogo from "./assets/img/spotify.svg";
import GoogleLogo from "./assets/img/google.svg";
import AmazonLogo from "./assets/img/amazon.svg";
import AppleLogo from "./assets/img/apple.svg";
import PocketLogo from "./assets/img/pocketcast.svg";

function AboutPage() {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 200);
    });
  }, []);
  return (
    <div className="enjaname-home-page">
      <div
        className={
          "w-100 header-section flex align-center " +
          (scroll ? "header-active" : "")
        }
      >
        <div className="container w-100">
          <div className="flex justify-between px-32 xs:px-20">
            <Link to="/">
              <img src={LogoImage} alt="Enjaname" />
            </Link>
            <div className="flex align-center">
              <Link
                to="/about"
                className="f-14 f-w-500 text-primary text-hover-primary bg-white-opacity-1 px-16 py-8 xs:px-12 xs:py-6 radius-50 mr-8 xs:mr-2"
              >
                About
              </Link>
              <a
                href="https://linktr.ee/enjaname"
                target="_blank"
                rel="noreferrer"
                className="flex f-14 f-w-500 text-primary bg-white-opacity-05 px-16 py-8 xs:px-12 xs:py-6 radius-50"
              >
                Podcast Links
              </a>
              {/* <Link to="/about">
                <h3 className="f-14 f-w-600 text-primary">
                  Contact and Support
                </h3>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
      <div className="enjaname-cover-section about-section"></div>
      <div className="enjaname-podcast-details">
        <div className="mt-60 xs:mt-40 xs:px-20 xs:text-left">
          <p className="f-24 xs:f-18 xs:l-h-26 l-h-36 f-w-300 text-tertiary">
            என் ஜனத்தின் வரலாறு, தொழில், உணர்தல், நம்பிக்கை, அறிவியல், உலகம்,
            கலை மற்றும் பண்பாடு. அவர்கள் பொதுவான வாழ்க்கை பிரச்சினைகள் மற்றும்
            தவறுகள்பற்றி. தோல்விகள்மூலம் நாம் கற்றுக்கொண்ட பாடங்களைக்
            கொண்டாடுவோம்.
          </p>
          <p className="f-24 xs:f-18 xs:l-h-28 l-h-36 f-w-300 text-tertiary mt-20">
            This episodes will discuss about our self-realization, careers,
            history, politics, art, and culture. We also talk about concerns and
            issues in ordinary life. Let’s rejoice in our imperfections and
            failures.
          </p>
          <p className="f-20 l-s-20 l-h-36 mt-20 text-tertiary text-center">
            ***
          </p>
        </div>
        <div className="flex justify-center xs:justify-center mt-24 xs:px-16">
          <div className="flex xs:flex-column column-gap-8 mt-12">
            <a
              href="https://linktr.ee/enjaname"
              target="_blank"
              rel="noreferrer"
              className="flex f-14 l-h-32 px-20 py-4 xs:justify-center text-secondary bg-white-opacity-05 radius-50 xs:mb-12"
            >
              Podcast Links
            </a>
            <a
              href="https://www.instagram.com/enjaname/"
              target="_blank"
              rel="noreferrer"
              className="flex f-14 l-h-32 px-20 py-4 xs:justify-center text-secondary bg-white-opacity-05 radius-50 xs:mb-12"
            >
              Instagram
            </a>
            <div className="flex align-center px-4 py-2 bg-white-opacity-05 radius-50">
              <a
                href="https://open.spotify.com/show/3tHiB0EyOteMykUCHhxJeL?utm_medium=share&utm_source=linktree"
                target="_blank"
                rel="noreferrer"
                className="flex px-8 py-4"
              >
                <img src={SportifyLogo} alt="" className="img-20" />
              </a>
              <a
                href="https://podcasts.apple.com/us/podcast/enjaname-%E0%AE%8E%E0%AE%A9-%E0%AE%9C%E0%AE%A9%E0%AE%AE/id1693076585?ign-itscg=30200&ign-itsct=lt_p"
                target="_blank"
                rel="noreferrer"
                className="flex px-8 py-4"
              >
                <img src={AppleLogo} alt="" className="img-20" />
              </a>
              <a
                href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy9lMmQ4Mjg0NC9wb2RjYXN0L3Jzcw"
                target="_blank"
                rel="noreferrer"
                className="flex px-8 py-4"
              >
                <img src={GoogleLogo} alt="" className="img-20" />
              </a>
              <a
                href="https://music.amazon.in/podcasts/57fb7276-1c46-4a7a-8d68-af9ea2d87dce/enjaname-%E0%AE%8E%E0%AE%A9%E0%AF%8D%E0%AE%9C%E0%AE%A9%E0%AE%AE%E0%AF%87"
                target="_blank"
                rel="noreferrer"
                className="flex px-8 py-4"
              >
                <img src={AmazonLogo} alt="" className="img-20" />
              </a>
              <a
                href="https://pca.st/6cz96wa4"
                target="_blank"
                rel="noreferrer"
                className="flex px-8 py-4"
              >
                <img src={PocketLogo} alt="" className="img-20" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-section flex justify-between py-24 px-32 bg-white-opacity-05 mt-60">
        <div className="container w-100 flex justify-between">
          <p className="f-13 f-w-400 text-primary">
            Anandakumar{" "}
            <a
              href="https://jeoanand.com"
              target="_blank"
              rel="noreferrer"
              className="text-secondary ml-6 radius-50"
            >
              @jeoanand
            </a>
          </p>
          <p className="f-13 f-w-400 text-tertiary">Made ❤️ TN, India</p>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
