import React from "react";

export const Spotify = ({
  link,
  style = {},
  wide = false,
  width = wide ? "100%" : "50%",
  height = wide ? 160 : 500,
  frameBorder = 0,
  allow = "encrypted-media",
  ...props
}) => {
  const url = new URL(link);
  return (
    <iframe
      title="Spotify Web Player"
      src={`https://open.spotify.com/embed${url.pathname}`}
      width={width}
      height={height}
      frameBorder={frameBorder}
      allow={allow}
      style={{
        borderRadius: 8,
        ...style,
      }}
      {...props}
    />
  );
};
